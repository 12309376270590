import React, { useState, useContext, useCallback } from 'react'
import Link from 'gatsby-link'
//import SiteTitle from './SiteTitle';
import { navigate } from 'gatsby'
//import CheeseburgerMenu from 'cheeseburger-menu'

import { AccordionContext } from './Layout'

const h2Styles = {
  fontSize: '2rem',
  margin: '2px',
}

const CheeseburgerMenu = ({isOpen, closeCallback, children}) => {
  return(<div className="cheese-burger">
      {isOpen && <div className="cheese-burger-content" onClick={closeCallback}>
            {children}
          </div>
        }

  </div>
  )
}

const NavigationBar = ({ secciones, className }) => {
  const [accordionRefs] = useContext(AccordionContext)
  const [menuIsOpen, closeMenu] = useState(false)

  const handleClick = useCallback(() => {
    // handle the click event
    closeMenu(!menuIsOpen)
  }, [])

  return (
    <header id="header" className={className}>
      <Link to="/" className="">
        <img src="../../icons/icon-48x48.png" className="icon" />
      </Link>

      <CheeseburgerMenu
        isOpen={menuIsOpen}
        closeCallback={handleClick}
        right={true}
        backgroundColor={'#4444449c'}
      >
        <div id="mobileMenu">
          <ul
            className="icons"
            style={{
              display: 'grid',
              alignContent: 'end',
              justifyItems: 'right',
              padding: '1rem',
              height: '100vh',
            }}
          >
            <li
              key={'about'}
              onClick={() => {
                navigate('/#aboutStart')
                closeMenu(false)
                try {
                  setTimeout(accordionRefs[0]?.current?.toggleAccordion(), 500)
                } catch (e) {}
              }}
            >
              <h2 style={h2Styles}>NUESTRO ESTUDIO</h2>
            </li>
            {secciones.map((seccion, index) => (
              <li
                key={seccion.id}
                onClick={() => {
                  navigate('/#servicios' + index)
                  closeMenu(false)
                  try {
                    setTimeout(
                      accordionRefs[index + 1]?.current?.toggleAccordion(),
                      500
                    )
                  } catch (e) {}
                }}
              >
                <h2 style={h2Styles}>{seccion.name}</h2>
              </li>
            ))}
        
            <li
              key={'contact'}
              onClick={() => {
                navigate('/#contact')
                closeMenu(false)
              }}
            >
              <h2 style={h2Styles}>CONTACTO</h2>
            </li>
            <li>
              <a
                style={{ marginRight: '0.5rem' }}
                href="https://www.facebook.com/zappdiseno"
                className="icon alt fa-facebook"
                aria-label="Read more about ZAPP IN THEIR FACEBOOK PAGE"
              >
                <span className="label">Facebook</span>
              </a>
              <a
                style={{ marginRight: '0.5rem' }}
                href="https://www.instagram.com/zappdiseno/"
                className="icon alt fa-instagram"
                aria-label="Read more about ZAPP IN THEIR INSTAGRAM PAGE"
              >
                <span className="label">Instagram</span>
              </a>
              <a
                style={{ marginRight: '0.5rem' }}
                href="https://api.whatsapp.com/send?phone=573105041115&text=Quiero información de Zapp"
                className="icon alt fa-whatsapp"
                aria-label="call us on Whatsapp"
              >
                <span className="label">Whatsapp</span>
              </a>
            </li>
          </ul>
        </div>
      </CheeseburgerMenu>

      <div className="desktop-bar">
        <ul className="icons">
          <li
            key={'about'}
            onClick={() => {
              navigate('/#aboutStart')
              try {
                setTimeout(() => {
                  accordionRefs[0]?.current?.toggleAccordion()
                }, 1500)
              } catch (e) {}
            }}
          >
            <h3>NUESTRO ESTUDIO</h3>
          </li>

          {secciones.map((seccion, index) => (
            <li
              key={seccion.id}
              onClick={() => {
                navigate('/#servicios' + index)
                try {
                  setTimeout(
                    accordionRefs[index + 1]?.current?.toggleAccordion(),
                    500
                  )
                } catch (e) {}
                /* setTimeout(document.getElementById(seccion.id).scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"}), 1000);*/
              }}
            >
              <h3>{seccion.name}</h3>
            </li>
          ))}

          <li
            key={'contact'}
            onClick={() => {
              navigate('/#contact')
            }}
          >
            <h3>CONTACTO</h3>
          </li>

          <li>
            <a
              href="https://www.facebook.com/zappdiseno"
              className="icon alt fa-facebook"
              aria-label="Read more about ZAPP IN THEIR FACEBOOK PAGE"
            >
              <span className="label">Facebook</span>
            </a>
          </li>
          <li>
            <a
              href="https://www.instagram.com/zappdiseno/"
              className="icon alt fa-instagram"
              aria-label="Read more about ZAPP IN THEIR INSTAGRAM PAGE"
            >
              <span className="label">Instagram</span>
            </a>
          </li>
          <li>
            <a
              href="https://api.whatsapp.com/send?phone=573105041115&text=Quiero información de Zapp"
              className="icon alt fa-whatsapp"
              aria-label="call us on Whatsapp"
            >
              <span className="label">Whatsapp</span>
            </a>
          </li>
          <li>
            {' '}
            <iframe
              src={`https://www.facebook.com/plugins/share_button.php?href=${
                typeof window !== 'undefined' ? window.location.href : '#'
              }&layout=button&size=small&width=89&height=20&appId`}
              width="89"
              height="20"
              style={{ border: 'none', overflow: 'hidden' }}
              scrolling="no"
              frameBorder="0"
              allowFullScreen={true}
              allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
            ></iframe>
          </li>
        </ul>
      </div>

      <div
        className="mobile-bar"
        style={{ position: 'absolute', right: '1rem', zIndex: '9999' }}
        onClick={() => closeMenu(!menuIsOpen)}
      >
        <h1>Menu</h1>
      </div>
    </header>
  )
}

export default React.memo(NavigationBar)
