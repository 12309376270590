import React from 'react';

const Contact = (props) => (
    <section id="contact">
        <div className="inner">
            <section>
                <form method="post" action="mailto:zappdiseno@gmail.com" >
                    <div className="field half first">
                        <label htmlFor="name">Nombre</label>
                        <input type="text" name="name" id="name" placeholder="Tu nombre" />
                    </div>
                    <div className="field half">
                        <label htmlFor="email">Email</label>
                        <input type="email" name="email" id="email" placeholder="correo@mail.com" />
                    </div>
                    <div className="field">
                        <label htmlFor="message">Mensaje</label>
                        <textarea name="message" id="message" rows="6" placeholder="Tu mensaje"></textarea>
                    </div>
                    <ul className="actions">
                    <li> <a href="mailto:zappdiseno@gmail.com"><input type="submit" value="Enviar Mensaje" className="special" /></a></li>
                        <li><input type="reset" value="Limpiar" /></li>
                    </ul>
                </form>
            </section>
            <section className="split">
                <section>
                <a href="mailto:zappdiseno@gmail.com"> <div className="contact-method">
                        <span className="icon alt fa-envelope"></span>
                        <h3>Email</h3>
                        <a href="mailto:zappdiseno@gmail.com">zappdiseno@gmail.com</a>
                    </div></a>
                </section>
                <section>
                <a href="https://api.whatsapp.com/send?phone=573105041115&text=Quiero información de Zapp"><div className="contact-method">
                        <span className="icon alt fa-phone"></span>
                        <h3>Cel.</h3>
                        <a href="https://api.whatsapp.com/send?phone=573105041115&text=Quiero información de Zapp">(+057) 3105041115</a>
                    </div></a>
                </section>
                <section>
                    <div className="contact-method">
                        <span className="icon alt fa-home"></span>
                        <h3>Dirección</h3>
                        <span>Medellín ,<br />
                        Colombia</span>
                    </div>
                </section>
            </section>
        </div>
    </section>
)

export default React.memo(Contact)
