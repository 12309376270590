import React, { useState }  from 'react';
import Helmet from 'react-helmet';
import 'normalize.css';
import '../assets/scss/main.scss';
import NavigationBar from './NavigationBar';
//import Menu from './Menu'
import Contact from './Contact';
import Footer from './Footer';


// Creating the context object and passing the default values.
const AccordionContext = React.createContext({
    accordionRefs: []
});

export function AccordionProvider({ children }) {
    // we need to stick state in here
    const [order, setOrder] = useState([]);
    return (
      <AccordionContext.Provider value={[order, setOrder]}>
        {children}
      </AccordionContext.Provider>
    );
  }


class Layout extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isMenuVisible: false,
            isMenuOpen: false,
            loading: 'is-loading',
            datos: this.props.datos
        }
        this.handleToggleMenu = this.handleToggleMenu.bind(this);
        this.secciones = [  {id:"BRANDING",name:"BRANDING"},
        {id:"INTERIORISMO", name:"INTERIORISMO"},
        {id:"PRODUCTOS", name:"PRODUCTOS"},
        {id:"CONSULTORIA", name:"CONSULTORÍA"}
      ];
    }

    componentDidMount () {
         this.timeoutId = setTimeout(() => {
            this.setState({loading: '' , datos: this.props.datos});
        }, 100);
    }

    componentWillUnmount () {
        if (this.timeoutId) {
            clearTimeout(this.timeoutId);
        }
    }

    handleToggleMenu () {
        this.setState({
            isMenuVisible: !this.state.isMenuVisible,
            isMenuOpen: !this.state.isMenuOpen
        })
    }

    render() {
        const { children, showFooter=true, showContact=true, accordionRefs } = this.props

        return (
            <React.Fragment>
                <Helmet
                    title="Zapp Diseño"
                    meta={[
                        { name: 'description', content: 'Somos un estudio creativo y nacimos para brindarte soluciones a problemas de diseño...' },
                        { name: 'keywords', content: 'diseño, latinoamericano, zapp, interiorismo, grafico, ingeniería, industrial, medellín, identidad corporativa, desarrollo de productos' }
                        ]} >
                </Helmet>
                <div className={`body ${this.state.loading} ${this.state.isMenuVisible ? 'is-menu-visible' : ''}`}>
                    <div id="wrapper">

                            <NavigationBar
                            siteTitle={this.state.datos ? this.state.datos.site.siteMetadata.title: ''}                       
                            className={this.state.isScrolled ? '' : 'alt'}
                            secciones={this.secciones}
                            />
                            {children}
                            {showContact && <Contact />}
                            {showFooter && <Footer secciones={this.secciones}/>}
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export {Layout, AccordionContext};
