import React from 'react'
import { navigate } from "gatsby"

const Footer = ({secciones}) => (
    <footer id="footer">
        <div className="inner">
            <ul className="icons">

            <li key={'contact'} onClick={() => { navigate('/#contact'); }}>
                <h2>NUESTRO ESTUDIO</h2>
            </li>

            {secciones.map(
                (seccion, index) => (
                <li key={seccion.id} onClick={() => { navigate('/#servicios'+index);  /* setTimeout(document.getElementById(seccion.id).scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"}), 1000);*/}}>    
                    <h2>
                        {seccion.name}          
                    </h2>
                </li>    
                ) 
            )}

            <li key={'about'} onClick={() => { navigate('/#aboutStart'); }}>    
                <h2 >NOSOTROS</h2>
            </li>
            <br />

            <li><a href="https://www.facebook.com/zappdiseno" className="icon alt fa-facebook" aria-label="Read more about ZAPP IN THEIR FACEBOOK PAGE"><span className="label">Facebook</span></a></li>
            <li><a href="https://www.instagram.com/zappdiseno/" className="icon alt fa-instagram" aria-label="Read more about ZAPP IN THEIR INSTAGRAM PAGE"><span className="label">Instagram</span></a></li>
            <li><a href="https://api.whatsapp.com/send?phone=573105041115&text=Quiero información de Zapp" className="icon alt fa-whatsapp" aria-label="call us on Whatsapp"><span className="label">Whatsapp</span></a></li>


             
            </ul>
            <ul className="copyright">
                <li>&copy; <a href="https://www.instagram.com/zappdiseno/"  aria-label="Read more about ZAPP IN THEIR INSTAGRAM PAGE" >#zapplatinoamerica </a></li>
            </ul>
        </div>
    </footer>
)

export default React.memo(Footer)
